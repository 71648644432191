<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table ref="freelancersTable" class="position-relative text-nowrap" :items="quizStats" responsive :fields="tableColumns"
               show-empty empty-text="No matching records found" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

        <!-- Column: # -->
        <template #cell(seq)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Column: Client Name -->
        <template #cell(clientName)="data">
          <b-link v-if="data.item.clientId" class="font-weight-bold d-block text-nowrap"
                  :to="{name: 'UsersEdit', params: {userId: data.item.clientId}}">
            {{ data.item.clientName }}
          </b-link>

          <span v-else>
            n/a
          </span>
        </template>

        <!-- Column: Result -->
        <template #cell(result)="data">
          <div v-if="data.item.majors.length">
            <span class="font-weight-bolder mb-1">Majors:</span>
            <div v-for="(style, index) in data.item.majors" :key="index">
              {{ style.name + ': ' }}
              <span class="font-weight-bolder">{{ style.percentage + '%' }}</span>
            </div>
          </div>

          <div v-if="data.item.minors.length" class="mt-2">
            <span class="font-weight-bolder mb-1">Minors:</span>
            <div v-for="(style, index) in data.item.minors" :key="index">
              {{ style.name + ': ' }}
              <span class="font-weight-bolder">{{ style.percentage + '%' }}</span>
            </div>
          </div>
        </template>

        <!-- Column: Created AT-->
        <template #cell(quizTakenDate)="data">
          {{ new Date(data.item.quizTakenDate).toISOString().split('T')[0] }}
        </template>

        <!-- Column: Selected Images -->
        <template #cell(selectedImages)="data">
          <div class="row row-cols-2">
            <a v-for="(image, index) in data.item.selectedImages" :key="index"
               :href="image"
               target="_blank"
               class="d-block">
              {{ 'image ' + (index + 1) }}
            </a>
          </div>
        </template>

        <!-- Column: Assigned Projects -->
        <template #cell(assignedProjects)="data">
          <div v-if="data.item.assignedProjects.length">
            <div v-for="(assignedProject, index) in data.item.assignedProjects" :key="index">
              <b-link v-if="data.item.clientId" class="font-weight-bold d-block text-nowrap"
                      :to="{ name: 'ProjectDetails', params: { projectId: assignedProject.id } }">
                {{ assignedProject.name }}
              </b-link>
            </div>
          </div>
          <span v-else>
            n/a
          </span>
        </template>

        <!-- Column: calculations -->
        <template #cell(calculations)="data">
          <div class="font-weight-bolder">{{'- Total Points:' + ' ' + data.item.totalWeight }}</div>

          <div class="mt-2">
            <span class="mb-1 d-inline-block font-weight-bolder">- Style Percentages:</span>
            <div v-for="(style, index) in data.item.styles" :key="'stylePercentage' + index">
              {{ style.name + ': ' }}
              <span class="font-weight-bolder">{{ style.percentage + '%' }}</span>
            </div>
          </div>

          <div class="mt-2">
            <span class="mb-1 d-inline-block font-weight-bolder">- Classifications:</span>

            <div>
              <span class="d-inline-block font-weight-bold">- Majors:</span>
            </div>
            <div v-for="(style, index) in data.item.majors" :key="'styleNameMajor' + index">
              {{ style.name }}
            </div>

            <div>
              <span class="mt-1 d-inline-block font-weight-bold">- Minors:</span>
            </div>
            <div v-for="(style, index) in data.item.minors" :key="'styleNameMinor' + index">
              {{ style.name }}
            </div>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalQuizStats" :per-page="perPage" first-number
                          last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox
  },

  data() {
    return {
      currentPage: 1,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      nameQuery: '',
      emailQuery: '',
      tableColumns: [
        {
          key: 'seq',
          label: '#',
          sortable: true
        },
        {
          key: 'clientName',
          label: 'Client Name',
          sortable: true
        },
        {
          key: 'result',
          label: 'Result',
          sortable: true
        },
        {
          key: 'quizTakenDate',
          label: 'Date',
          sortable: true
        },
        {
          key: 'selectedImages',
          label: 'Selected Images',
          sortable: true
        },
        {
          key: 'assignedProjects',
          label: 'Assigned Projects',
          sortable: true
        },
        {
          key: 'calculations',
          label: 'Calculations',
          sortable: true
        }
      ],
      sortBy: 'CreatedOn',
      sortDesc: true,
      quizStats: [],
      totalQuizStats: 0
    };
  },

  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.quizStats.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.quizStats.length,
        of: this.totalQuizStats,
      }
    },

    queryParams() {
      return {
        sortBy: this.sortBy,
        sortOrder: this.sortDesc ? 1 : 0,
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
      }
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetchQuizStats();
      },
      immediate: true
    }
  },

  methods: {
    async fetchQuizStats() {
      const params = this.queryParams;
      params.name = this.nameQuery;
      params.email = this.emailQuery;

      const response = await this.$http.get('/Quizzes/stats', {
        params
      });
      this.quizStats = response.data.results ?? [];
      this.totalQuizStats = response.data.count;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
